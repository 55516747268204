import { IUser,IYYResponse,ICardInfo,ICardSearch, IPage, PAGE_SIZE,IPrintTemplateInfo,IPrintTemplateItem } from "@/ts";
import http from ".";
import { v4 as uuidv4 } from "uuid";
// 获得标签列表

// SDk 资产云2.0 获取单位orgId
export function sdkGetPersonId(userId:any):Promise<IPrintTemplateItem[]> {
  // const param={
  //     userId: 1272914195944853617,
  // };
  return pdmanageGetInfo<IPrintTemplateItem[]>('/app/tenantAuth?action=2&userId='+userId.userId);
}

async function pdmanageGetInfo<T>(url:string, param?:any):Promise<T> {
  const res=await http.get<T>("/pandian/pdmanage"+url, param);
  if(res) {
      return res;
  }else {
      return <T>{};
  }
}
